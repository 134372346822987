/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */

import { Box, Stack, styled, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

import { Body1SemiBold, COLORS, Loading, MultiSelectControlledOption } from "components";

import { ChartWrapper } from "../charts/chartLayoutWrapper";
import {
  FINANCIAL_BY_COUNTRIES_HIGHCHARTS_DATA,
  FINANCIAL_BY_FACILITY_TYPES_HIGHCHARTS_DATA,
  FINANCIAL_BY_REGION_HIGHCHARTS_DATA,
  FINANCIAL_BY_SITE_HIGHCHARTS_DATA,
  FINANCIAL_BY_UT_HIGHCHARTS_DATA,
  FINANCIAL_BY_UT_HIGHCHARTS_NATGAS_DATA,
  FINANCIAL_MONTHLY_HIGHCHARTS_DATA, // FINANCIAL_BY_UT_HIGHCHARTS_ELE_DATA,
  FINANCIAL_YEARLY_HIGH_CHARTS_DATA,
  FINANCIAL_SINGLE_BAR_CHART,
} from "../consts";
import {
  findCommonElements,
  generateMonths,
  getRequiredYearDetails,
  parseCountriesEmissionsData,
  parseEmissionsDataMonthly,
  parseFTEmissionsData,
  parseMonthEmissionsData,
  parseRegionsEmissionsData,
  parseSitemissionsData,
  parseUtilityEmissionsData,
  parseYearEmissionsData,
} from "../utils";
import { debounce } from "lodash";

type Props = {
  monthFinancialYear: string;
  setMonthFinancialYear: (data: string) => void;
  financialCarbonYear: string;
  setFinancialCarbonYear: (data: string) => void;
  monthlyData: unknown;
  sitesData: unknown;
  yearlyData: unknown;
  regionsData: unknown;
  utilityData: unknown;
  facilityTypesData: unknown;
  countriesResp: unknown;
  sitesOptions: MultiSelectControlledOption[];
  countryRegionsList: string[];
  facilityTypesList: string[];
  utilityTypesOptions: MultiSelectControlledOption[];
  unitLevelCostData: any;
  EMSpendData: unknown;
  startMonth: string;
  endMonth: string;
  selectedYear: number;
  financialLoadingStates: any;
  openAccordian: any;
};

export const OverallFinancialsComponent = ({
  financialLoadingStates,
  monthlyData,
  sitesData,
  yearlyData,
  regionsData,
  utilityData,
  facilityTypesData,
  countriesResp,
  sitesOptions,
  countryRegionsList,
  facilityTypesList,
  utilityTypesOptions,
  monthFinancialYear,
  setMonthFinancialYear,
  unitLevelCostData,
  EMSpendData,
  financialCarbonYear,
  setFinancialCarbonYear,
  startMonth,
  endMonth,
  selectedYear,
  openAccordian,
}: Props) => {

  const Accordion = styled(MuiAccordion)({
    border: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "white",
    "&:before": {
      display: "none",
    },
  });

  const CustomAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    alignItems: "center",
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      overflow: "hidden", // Prevent content overflow
      whiteSpace: "nowrap", // Keep text inline
    },
    "& .MuiIconButton-root": {
      transition: "transform 0.3s",
      transform: "rotate(0deg)",
    },
    "& .Mui-expanded .MuiIconButton-root": {
      transform: "rotate(90deg)",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const [expanded, setExpanded] = React.useState<string | false>("");

  const [monthlyEmissionsHighChartsData, setMonthlyEmissionsHighChartsData] =
    useState(FINANCIAL_MONTHLY_HIGHCHARTS_DATA);

  const [siteEmisionsHighChartsData, setSiteEmisionsHighChartsData] = useState(
    FINANCIAL_BY_SITE_HIGHCHARTS_DATA,
  );

  const [yearEmissionsData, setYearEmissionsData] = useState(
    FINANCIAL_YEARLY_HIGH_CHARTS_DATA,
  );

  const [regionsEmissionData, setRegionsEmissionData] = useState(
    FINANCIAL_BY_REGION_HIGHCHARTS_DATA,
  );

  const [ftEmissionsData, setFTEmissionsData] = useState(
    FINANCIAL_BY_FACILITY_TYPES_HIGHCHARTS_DATA,
  );

  const [utEMDataTwoFields, setUTEMDataTwoFields] = useState(
    FINANCIAL_BY_UT_HIGHCHARTS_DATA,
  );

  const [unitLevelHighchartData, setUnitLevelHighchartData] = useState(
    FINANCIAL_BY_UT_HIGHCHARTS_DATA,
  );
  const [unitLevelHighchartNGData, setUnitLevelHighchartNGData] = useState(
    FINANCIAL_BY_UT_HIGHCHARTS_NATGAS_DATA,
  );

  const [countriesEMData, setCountriesEMData] = useState(
    FINANCIAL_BY_COUNTRIES_HIGHCHARTS_DATA,
  );

  const [carbonemissionsSpendData, setCarbonEmissionsSpendData] =
    useState(FINANCIAL_SINGLE_BAR_CHART);

  useEffect(() => {
    if (monthlyData) {

      const parsedData = parseEmissionsDataMonthly(
        monthlyData,
        startMonth,
        endMonth,
        "amount",
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_MONTHLY_HIGHCHARTS_DATA),
      );
      const monthsHeaders = generateMonths(startMonth, endMonth, selectedYear);
      const formattedMonthHeaders = monthsHeaders.map((monthValue) => {
        const [year, monthName] = monthValue.split(" ");
        const shortYear = year.slice(-2);
        return `${monthName} '${shortYear}`;
      });
      const xAxisData = formattedMonthHeaders;
      updatedChartsData.series = parsedData;
      updatedChartsData.xAxis.categories = xAxisData;
      updatedChartsData.yAxis.title.text = "$1000 spent";
      setMonthlyEmissionsHighChartsData(updatedChartsData);

    }

  }, [monthlyData, monthFinancialYear]);

  useEffect(() => {
    if (utilityData) {
      const utData = parseUtilityEmissionsData(
        utilityData,
        utilityTypesOptions,
        "amount",
        startMonth,
        endMonth,
      );
      const updatedTwoFieldChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_BY_UT_HIGHCHARTS_DATA),
      );
      updatedTwoFieldChartsData.xAxis.categories = utilityTypesOptions.map(
        (e) => e.label,
      );
      let indexArraymain: any[] = [];
      utData.filter((e: any) => {
        let indexArray: any[] = [];
        e.data.forEach((key: any, index: any) => {
          if (key === 0) {
            indexArray.push(index);
          }
        });
        indexArraymain.push(indexArray);
      });

      const commonElements = findCommonElements(...indexArraymain);
      updatedTwoFieldChartsData.series = utData;
      const sort = commonElements.sort((a, b) => b - a);
      sort.forEach((element: Number) => {
        updatedTwoFieldChartsData.xAxis.categories.splice(Number(element), 1);
        utData.filter((e: any) => {
          e.data.splice(Number(element), 1);
        });
      });
      updatedTwoFieldChartsData.yAxis.title.text = "therms";
      updatedTwoFieldChartsData.yAxis.title.text = "$1000 spent";
      setUTEMDataTwoFields(updatedTwoFieldChartsData);
    }
  }, [utilityData]);

  useEffect(() => {
    const filteredOptions = utilityTypesOptions.filter(
      (option) => option.label === "Electricity",
    );
    if (unitLevelCostData) {
      const utData = parseUtilityEmissionsData(
        unitLevelCostData,
        filteredOptions,
        "costOfUtility",
        startMonth,
        endMonth,
      );

      const updatedChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_BY_UT_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = utilityTypesOptions.map(
        (e) => e.label,
      );
      updatedChartsData.xAxis.categories = ["Electricity"];
      updatedChartsData.series = utData;
      updatedChartsData.yAxis.title.text = "$ spent/usage unit";
      setUnitLevelHighchartData(updatedChartsData);
    }
  }, [unitLevelCostData]);

  useEffect(() => {
    const filteredOptions = utilityTypesOptions.filter(
      (option) => option.label.toLowerCase() === "natural gas",
    );
    if (unitLevelCostData) {
      const utData = parseUtilityEmissionsData(
        unitLevelCostData,
        filteredOptions,
        "costOfUtility",
        startMonth,
        endMonth,
      );

      const updatedChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_BY_UT_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = utilityTypesOptions.map(
        (e) => e.label,
      );
      updatedChartsData.xAxis.categories = ["Natural Gas"];
      updatedChartsData.series = utData;
      updatedChartsData.yAxis.title.text = "$ spent/usage unit";
      setUnitLevelHighchartNGData(updatedChartsData);
    }
  }, [unitLevelCostData]);

  useEffect(() => {
    if (EMSpendData) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(EMSpendData),
        financialCarbonYear.replace("FY", ""),
      );
      const parsedData = parseMonthEmissionsData(
        EMSpendData,
        "emissionBySpend",
      );
      const updatedChartsData = JSON.parse(JSON.stringify(FINANCIAL_SINGLE_BAR_CHART));
      updatedChartsData.series.push(parsedData);
      updatedChartsData.yAxis.title.text = "CO2 per $1000 spent";
      updatedChartsData.plotOptions.series.dataLabels.format = "{point.y:.3f}";
      setCarbonEmissionsSpendData(updatedChartsData);
      if (updateYear) {
        setFinancialCarbonYear(`FY${reqYear}`);
      }
    }
  }, [EMSpendData, financialCarbonYear]);

  useEffect(() => {
    if (sitesData) {
      const parsedData = parseSitemissionsData(
        sitesData,
        sitesOptions,
        "amount",
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_BY_SITE_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = sitesOptions.map((e) => e.label);
      let indexArraymain: any[] = [];
      parsedData.filter((e: any) => {
        let indexArray: any[] = [];
        e.data.forEach((key: any, index: any) => {
          if (key === 0) {
            indexArray.push(index);
          }
        });
        indexArraymain.push(indexArray);
      });

      const commonElements = findCommonElements(...indexArraymain);
      const modifiedsiteData = parsedData.map((series: any) => {
        return {
          name: `FY${series.name}`,
          data: series.data,
        };
      });

      updatedChartsData.series = modifiedsiteData;
      const sort = commonElements.sort((a, b) => b - a);
      sort.forEach((element: Number) => {
        updatedChartsData.xAxis.categories.splice(Number(element), 1);
        parsedData.filter((e: any) => {
          e.data.splice(Number(element), 1);
        });
      });
      updatedChartsData.yAxis.title.text = "therms";
      updatedChartsData.yAxis.title.text = "$1000 spent";
      setSiteEmisionsHighChartsData(updatedChartsData);
    }
  }, [sitesData]);

  useEffect(() => {
    if (yearlyData) {
      const parsedData = parseYearEmissionsData(yearlyData, "amount", startMonth, endMonth);
      const updatedChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_YEARLY_HIGH_CHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = Object.keys(yearlyData)
        .sort()
        .map((e) => `FY${e}`);
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "$1000 spent";
      setYearEmissionsData(updatedChartsData);
    }
  }, [yearlyData]);

  useEffect(() => {
    if (regionsData) {
      const parsedData = parseRegionsEmissionsData(
        regionsData,
        countryRegionsList,
        "amount",
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_BY_REGION_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = countryRegionsList;
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "$1000 spent";
      setRegionsEmissionData(updatedChartsData);
    }
  }, [regionsData]);

  useEffect(() => {
    if (facilityTypesData) {
      const parsedData = parseFTEmissionsData(
        facilityTypesData,
        facilityTypesList,
        "amount",
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_BY_FACILITY_TYPES_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = facilityTypesList;
      updatedChartsData.yAxis.title.text = "$1000 spent";
      const modifiedfTData = parsedData.map((series: any) => {
        return {
          name: `FY${series.name}`,
          data: series.data,
        };
      });

      updatedChartsData.series = modifiedfTData;
      setFTEmissionsData(updatedChartsData);
    }
  }, [facilityTypesData]);

  useEffect(() => {
    if (countriesResp) {
      const parsedData = parseCountriesEmissionsData(
        countriesResp,
        ["US", "Rest of world"],
        "amount",
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(FINANCIAL_BY_COUNTRIES_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = ["US", "Rest of world"];
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "$1000 spent";
      setCountriesEMData(updatedChartsData);
    }
  }, [countriesResp]);

  useEffect(() => {
    openAccordian(expanded)
  },[expanded])
  const dataCheck = (dataRes: any) => {
    const hasNonZeroValue = dataRes?.series?.some((item: any) => {
      if (Array.isArray(item?.data)) {
        return item.data.some((value: { y: number }) => value.y !== 0);
      }
      return false;
    });

    return hasNonZeroValue;
  };
  const dataCheckValue = (dataRes: any) => {
    const hasNonZeroValue = dataRes?.series?.some((item: any) => {
      if (Array.isArray(item?.data)) {
        return item.data.some((value: any) => value !== 0);
      }
      return false;
    });

    return hasNonZeroValue;
  };

  const monthlyConsumption = dataCheck(monthlyEmissionsHighChartsData);
  const hasFalseValue = dataCheck(siteEmisionsHighChartsData);
  const hasFalseyearConsumption = dataCheck(yearEmissionsData);
  const consumptionbyRegion = dataCheck(regionsEmissionData);
  const ftConsumption = dataCheck(ftEmissionsData);
  const countries = dataCheck(countriesEMData);
  const utEMDataTwo = dataCheck(utEMDataTwoFields);
  const unitLevelHighchart = dataCheckValue(unitLevelHighchartData);
  const unitLevelHighchartNG = dataCheckValue(unitLevelHighchartNGData);
  const carbonemissionsSpend = dataCheck(carbonemissionsSpendData);

  const debouncedSetExpanded = React.useCallback(
    debounce((panel: string | false) => setExpanded(panel), 100),
    []
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      debouncedSetExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          my: 2,
          background: "white",
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel1" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Time Period</Body1SemiBold>
        </CustomAccordionSummary>

        <AccordionDetails>
          {expanded === "panel1" &&
            <>
              {/* Energy Spend Month on Month */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: monthlyConsumption ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Energy Spend Month on Month
                  </Typography>
                }
                selectedYear={monthFinancialYear}
                setSelectedYear={setMonthFinancialYear}
                renderYearDropdown={monthlyConsumption}
                id="FinancialEnergySpendMonth"
              >
                {financialLoadingStates?.getFinancialsOnMonthlyLoading ? (<Loading />) : monthlyConsumption ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={monthlyEmissionsHighChartsData}

                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>

              {/* Energy Spend Year on Year */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: hasFalseyearConsumption ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Energy Spend Year on Year
                  </Typography>
                }
                id="FinancialEnergySpendYear"
              >
                {financialLoadingStates?.getFinancialsYearLoading ? (<Loading />) : hasFalseyearConsumption ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={yearEmissionsData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>

              {/* Carbon Emission by Spend Month on Month */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: carbonemissionsSpend ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Carbon Emission by Spend Month on Month
                  </Typography>
                }
                id="FinancialCarbonEmission"
                selectedYear={financialCarbonYear}
                setSelectedYear={setFinancialCarbonYear}
                renderYearDropdown={carbonemissionsSpend}
              >
                {financialLoadingStates?.getUnitLevelCostLoading ? (<Loading />) : carbonemissionsSpend ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={carbonemissionsSpendData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>
            </>}
        </AccordionDetails>
      </Accordion>

      {/* Utility Type */}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{
          my: 2,
          background: "white",
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel2" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Utility Type</Body1SemiBold>
        </CustomAccordionSummary>

        <AccordionDetails>
          {expanded === "panel2" &&
            <>
              {/* Spend by Utility Type */}
              <ChartWrapper
                styles={{ width: "50%" }}
                label={
                  <Typography
                    sx={{
                      color: utEMDataTwo ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Spend by Utility Type
                  </Typography>
                }
              >
                {financialLoadingStates?.getUnitLevelCostLoading ? (<Loading />) : utEMDataTwo ? (
                  <HighchartsReact
                    id="FinancialSpendbyUtility"
                    highcharts={Highcharts}
                    options={utEMDataTwoFields}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>

              {/* Unit-level cost of Electricity */}
              <ChartWrapper
                styles={{ width: "50%" }}
                id="FinancialUnit-levelElectricity"
                label={
                  <Typography
                    sx={{
                      color: unitLevelHighchart ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Unit-level cost of Electricity
                  </Typography>
                }
              >
                {financialLoadingStates?.getUnitLevelCostLoading ? (<Loading />) : unitLevelHighchart ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={unitLevelHighchartData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>

              {/* Unit-level cost of Natural Gas */}
              <ChartWrapper
                styles={{ width: "50%" }}
                label={
                  <Typography
                    sx={{
                      color: unitLevelHighchartNG ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Unit-level cost of Natural Gas
                  </Typography>
                }
                id="FinancialUnit-levelNatural Gas"
              >
                {financialLoadingStates?.getFinancialsUtilityLoading ? (<Loading />) : unitLevelHighchartNG ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={unitLevelHighchartNGData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>
            </>}
        </AccordionDetails>
      </Accordion>

      {/* Region / Country */}
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        sx={{
          my: 2,
          background: "white",
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel3" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Region / Country</Body1SemiBold>
        </CustomAccordionSummary>

        <AccordionDetails>
          {expanded === "panel3" &&
            <>
              {/* Spend by Region */}
              <ChartWrapper
                styles={{ width: "50%" }}
                label={
                  <Typography
                    sx={{
                      color: consumptionbyRegion ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Spend by Region
                  </Typography>
                }
                id="FinancialSpendbyRegion"
              >
                {financialLoadingStates?.getFinancialsRegionLoading ? (<Loading />) : consumptionbyRegion ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={regionsEmissionData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>

              {/* Spend by US vs Rest of world */}
              <ChartWrapper
                styles={{ width: "50%" }}
                label={
                  <Typography
                    sx={{
                      color: countries ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Spend by US vs Rest of world
                  </Typography>
                }
                id="FinancialSpendbyUS"
              >
                {financialLoadingStates?.getFinancialsFacilityTypeLoading ? (<Loading />) : countries ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={countriesEMData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>

              {/* Spend by Facility type */}
              <ChartWrapper
                styles={{ width: "50%" }}
                label={
                  <Typography
                    sx={{
                      color: ftConsumption ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Spend by Facility type
                  </Typography>
                }
                id="FinancialSpendbyFacility"
              >
                {financialLoadingStates?.getFinancialsFacilityTypeLoading ? (<Loading />) : ftConsumption ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={ftEmissionsData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>

            </>}
        </AccordionDetails>
      </Accordion>

      {/* Business Unit / Site Type */}
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        sx={{
          my: 2,
          background: "white",
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel4" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Business Unit / Site Type</Body1SemiBold>
        </CustomAccordionSummary>

        <AccordionDetails>
          {expanded === "panel4" &&
            <>
              {/* Spend by Site */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: hasFalseValue ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Spend by Site
                  </Typography>
                }
                id="FinancialSpendbySite"
              >
                {financialLoadingStates?.getFinancialsSiteLoading ? (<Loading />) : hasFalseValue ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={siteEmisionsHighChartsData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>
            </>}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
