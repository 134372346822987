import MaterialReactTable from "material-react-table";
import React from "react";

import { Loading } from "components";

import { useEmissionFactors } from "hooks";

import { SCIENTIFIC_UNIT_COLUMNS } from "./components";

export const ScientificUnit = ({ ReferencesData }: { ReferencesData: any }) => {
  const { isLoading } = useEmissionFactors();
  const parsedData = ReferencesData.map((str: string, index: any) => {
    try {
      return JSON.parse(str);
    } catch (error) {
      console.error(`Error parsing string at index ${index}:`, error);
      return null;
    }
  });
  const columns = SCIENTIFIC_UNIT_COLUMNS;
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <MaterialReactTable
          columns={columns}
          muiTableContainerProps={{
            sx: {
              "& thead tr:first-child": {
                "th:first-child": {
                  paddingLeft: "20px",
                },
                "th > div > div > div": {
                whiteSpace: "nowrap",
              }
              },
              "& tbody tr td:first-child": {
                paddingLeft: "20px",
              },
              height: "calc(100vh - 300px)",
              border: "1px solid #D9D9D9",
              borderRadius: "10px",
              minWidth: "100%",
              width: "100%",
              maxWidth: "100%",
              "@media (min-width: 600px)": {
                width: "100%",
              },
              "@media (min-width: 960px)": {
                width: "1100px",
              },
              "@media (min-width: 1280px)": {
                width: "1100px",
              },
              "@media (min-width: 1440px)": {
                width: "1100px",
              },
              "@media (min-width: 1680px)": {
                width: "1300px",
              },
              "@media (min-width: 2400px)": {
                width: "100%",
              },
            },
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: "10px",
              boxShadow: "none",
            },
          }}
          muiTopToolbarProps={{
            sx: {
              display: "none",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              display: "none",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              lineHeight: "30px",
              "& .MuiBadge-root": {
                display: "none",
              },
            },
          }}
          muiTableHeadRowProps={{
            sx: {
              "& > th": {
                paddingTop: 1,
                paddingBottom: 1,
              },
              height: "auto",
              backgroundColor: "#F8FAFC",
            },
          }}
          muiTableBodyRowProps={{
            sx: {
              height: "45px",
              backgroundColor: "white",
            },
          }}
          data={parsedData}
          enableColumnResizing
          enableDensityToggle={false}
          initialState={{ density: "compact" }}
          enablePagination={false}
          enableRowVirtualization
        />
      )}
    </div>
  );
};
