import { Modal } from "@mui/base";
import {
  AppBar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  AdaptedCollectorsMissingDocuments,
  getFiscalYearSettings,
  getMissingDocuments,
  getSitesByCountry,
} from "api";

import {
  EMPTY_CELL_VALUE,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2,
  Loading,
  MarginWrapper,
  MultiselectControlledField,
  Pagination,
  ROWS_PER_PAGE_OPTIONS,
  Table,
  UtilityBillType,
  UtilityBillTypeKey,
  usePagination,
} from "components";

import { useGetAllowedValues, useSites, useUtilityBillsYears } from "hooks";
import { useCountriesMultiselect } from "hooks/multiselect/countries";
import { useFilteredSitesMultiselect } from "hooks/multiselect/filteredSites";

import { LOCAL_STORAGE_KEYS, TABLE_HEADERS } from "consts";

import { generateMonths } from "pages/authenticated/dashboards";

import { useUserRole } from "recoils";

import { isNullOrUndefined } from "utils";

import { CollectorMonthView } from "./components";
import { MonthCell } from "./components/table/month-cell";
import { MISSING_DOCUMENTS_PAGE_QUERY_PARAMS } from "./consts";

export const MissingDocumentsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const collectorId = searchParams.get(
    MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.COLLECTOR_ID,
  );
  const month = searchParams.get(MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.MONTH);
  const onCollectorMonthViewClose = useCallback(
    () => setSearchParams({}),
    [setSearchParams],
  );

  const userRole = useUserRole();
  const { data: sites } = useSites();

  const result = Array.isArray(sites) ? sites.map((a) => a.id) : [];

  const siteIds = result?.toString();

  const [selectedSiteIds, setSelectedSiteIds] = useState<any>(null);

  const { data: availableYears } = useUtilityBillsYears();
  const { data: allowedValues } = useGetAllowedValues();

  const [fiscalYear, setFiscalYear] = useState("");

  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [monthHeaders, setMonthHeaders] = useState<any>([]);
  const [missingData, setMissingData] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [isActivatedMissing, setIsActivatedMissing] = useState<boolean>(false);
  const [utilityTypeId, setUtilityTypeId] = useState<any[]>([]);

  const NONE_PADDING_COLUMNS_IDS = new Set<string>();

  const {
    pageSize,
    pageNumber,
    onPageSizeChange,
    onPageChange,
    setInitialPage,
    updateQueryParamsWithInitialPage,
  } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
  });

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  const fiscalYears = availableYears && [
    ...availableYears.map((yearItem: any) => `FY${yearItem}`),
    `FY${nextYear}`,
  ];

  const yearValue = parseInt(fiscalYear.replace("FY", ""), 10) as number;

  const {
    isCountriesLoading,
    selectedCountry,
    setSelectedCountry,
    countriesList,
  } = useCountriesMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const generateDates = (
    year_Value: number,
    start_Month: string,
    end_Month: string,
  ) => {
    const adjustedYear = start_Month.toUpperCase() === "JAN" ? year_Value : year_Value - 1;
    const startDate = dayjs(`${start_Month} 1, ${adjustedYear}`);
    const endDate = dayjs(`${end_Month} 1, ${year_Value}`).endOf("month");
    const FromDate = startDate.format("YYYY-MM-DD");
    const ToDate = endDate.format("YYYY-MM-DD");
    return { FromDate, ToDate };
  };

  const { isSitesLoading, selectedSites, setSelectedSites, sitesOptions } =
    useFilteredSitesMultiselect({
      selectedCountry,
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    });

  const getMonthsfromSettings = () => {
    getFiscalYearSettings()
      .then((fiscalYearSettings: any) => {
        const startMonthValue = fiscalYearSettings.find(
          (setting: any) => setting.name === "company_month_start",
        )?.configValue;
        const endMonthValue = fiscalYearSettings.find(
          (setting: any) => setting.name === "company_month_end",
        )?.configValue;
        setStartMonth(startMonthValue);
        setEndMonth(endMonthValue);
      })
      .catch((error) => {
        console.error("Error fetching fiscal year settings:", error);
      });
  };

  useEffect(() => {
    getMonthsfromSettings();
  });

  useEffect(() => {
    const data = generateMonths(startMonth, endMonth, yearValue);
    setMonthHeaders(data);
  }, [startMonth, endMonth, fiscalYear, yearValue]);

  useEffect(() => {
    const { FromDate: fDate, ToDate: tDate } = generateDates(
      yearValue,
      startMonth,
      endMonth,
    );
    setFromDate(fDate);
    setToDate(tDate);
  }, [yearValue, startMonth, endMonth]);

  const fetchData = async () => {
    try {
      setDataLoading(true);
      let data;
      if (userRole.isDataProvider || userRole.isAdmin) {
        data = await getMissingDocuments({
          fromDate,
          toDate,
          utilityTypeIds: utilityTypeId?.map((t) => t.value),
          siteIds:
            selectedSiteIds?.length === 0
              ? siteIds
              : selectedSiteIds && selectedSiteIds.toString(),
        });
      } else {
        data = await getMissingDocuments({
          fromDate,
          toDate,
        });
      }

      if (selectedCountry && selectedCountry.length > 0) {
        let combinedFilteredSites: string[] = [];
        let combinedSelectedSiteNamesArray: string[] = [];

        if (Array.isArray(selectedCountry)) {
          const sitePromises = selectedCountry.map((country) =>
            getSitesByCountry(country.value),
          );
          const results = await Promise.all(sitePromises);
          const responses = results.flat();

          const filtered =
            sites?.filter(
              (site) => site?.name != null && responses.includes(site.name),
            ) ?? [];

          const filteredSiteNames = filtered
            .map((site) => site.name)
            .filter((name): name is string => name != null);
          combinedFilteredSites =
            combinedFilteredSites.concat(filteredSiteNames);

          if (selectedSites && selectedSites.length > 0) {
            const selectedSiteNames = selectedSites
              .map((site) => site.label)
              .filter((label): label is string => label != null);
            combinedSelectedSiteNamesArray =
              combinedSelectedSiteNamesArray.concat(selectedSiteNames);
          }
        } else {
          const response: string[] = await getSitesByCountry(selectedCountry);
          const filtered =
            sites?.filter(
              (site) => site?.name != null && response.includes(site.name),
            ) ?? [];

          const filteredSiteNames = filtered
            .map((site) => site.name)
            .filter((name): name is string => name != null);
          combinedFilteredSites =
            combinedFilteredSites.concat(filteredSiteNames);

          if (selectedSites && selectedSites.length > 0) {
            const selectedSiteNames = selectedSites
              .map((site) => site.label)
              .filter((label): label is string => label != null);
            combinedSelectedSiteNamesArray =
              combinedSelectedSiteNamesArray.concat(selectedSiteNames);
          }
        }

        if (selectedSites && selectedSites.length > 0) {
          const combinedSelectedSiteNamesSet = new Set(
            combinedSelectedSiteNamesArray,
          );
          data = data.filter((item) =>
            combinedSelectedSiteNamesSet.has(item.collector.siteName),
          );
        } else {
          const combinedFilteredSiteNamesSet = new Set(combinedFilteredSites);
          data = data.filter((item) =>
            combinedFilteredSiteNamesSet.has(item.collector.siteName),
          );
        }
      }

      setMissingData(data);
      setDataLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataLoading(false);
    }
  };

  const tableRows = useMemo(() => missingData || [], [missingData]);
  const activeCollectors = useMemo(() => missingData.filter((row: any) => row.collector.enabled) || [], [missingData]);
  const missingCollectors = useMemo(() => missingData.filter((row :any) => {
    let monthsToCheck = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    if((currentYear - 1) === yearValue) {
      const lastMonthToCheck = parseInt(dayjs().format("MM"), 10) - 1;
      monthsToCheck = monthsToCheck.slice(0, lastMonthToCheck);
    }
    const hasMissingData = monthsToCheck.some(currentMonth => 
      row.months[currentMonth] && row.months[currentMonth].usage === null
    );
    
    return hasMissingData;
  }) || [], [missingData, yearValue, currentYear]);
  

  const collectorMissingDocuments = useMemo(
    () => tableRows.find((r: any) => String(r.id) === collectorId)!,
    [collectorId, tableRows],
  );

  const style = {
    position: "absolute" as "absolute",
    top: "35%",
    left: "20%",
    transform: "scaleY(1.05)",
    width: "77%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 7,
    zIndex: 1,
    padding: "15px",
  };

  const filteredSelectedData = useMemo(() =>
    selectedSites.flatMap(site =>
      sites?.filter(
        row => row.name && row.name.toLowerCase() === site.label.toLowerCase(),
      ) || [],
    ),
    [selectedSites, sites]
  );


  useEffect(() => {
    const selectedIds = filteredSelectedData.map((site) => site.id);

    // Debounce the setSelectedSiteIds function
    const debouncedSetSelectedSiteIds = debounce(() => {
      setSelectedSiteIds(selectedIds);
    }, 300);

    debouncedSetSelectedSiteIds();

    // Cleanup function to cancel debounce on unmount or change
    return () => {
      debouncedSetSelectedSiteIds.cancel();
    };
  }, [filteredSelectedData]);

  useEffect(() => {
    setSelectedSites([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesList, selectedCountry]);

  const monthNamesMap: { [key: string]: number } = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  const monthColumns = monthHeaders.map((monthValue: string) => {
    if (!monthValue) return null;
    const [year, monthName] = monthValue.split(" ");
    const monthNumber = monthNamesMap[monthName];
    const paddedMonthNumber =
      monthNumber && monthNumber.toString().padStart(2, "0");
    const formattedYear = year.slice(-2);

    NONE_PADDING_COLUMNS_IDS.add(`${year}-${paddedMonthNumber}`);

    return {
      id: `${year}-${paddedMonthNumber}`,
      header: fiscalYear === "" ? monthName : `${monthName}'${formattedYear}`,
      accessorKey: `months.${paddedMonthNumber}`,
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    };
  });

  // Columns
  const MISSING_DOCUMENTS_COLUMNS: ColumnDef<AdaptedCollectorsMissingDocuments>[] =
    [
      {
        accessorKey: "collector.estimate",
        header: "Tags",
        enableSorting: false,
        // eslint-disable-next-line
        cell: ({ row }) => {
          const showIndicator = row.original.collector.estimate;
          const showRecIndicator = row?.original?.collector?.recs;
          return (
            <Box sx={{ display: "flex" }}>
              <Tooltip
                title={
                  showIndicator
                    ? "There are Parameter Based Estimations at this Site"
                    : ""
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    background: showIndicator ? "#D3D3D3" : "transparent",
                    color: showIndicator ? "black" : "transparent",
                    fontWeight: "bold",
                  }}
                >
                  {showIndicator ? "E" : ""}
                </div>
              </Tooltip>
              {showRecIndicator && (
                <Tooltip
                  title={
                    showRecIndicator
                      ? "This site has a REC applied to its emissions"
                      : ""
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "12px",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      background: showRecIndicator ? "#D3D3D3" : "transparent",
                      color: showRecIndicator ? "black" : "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {showRecIndicator ? "R" : ""}
                  </div>
                </Tooltip>
              )}
            </Box>
          );
        },
      },
      {
        header: "Site",
        accessorKey: "collector.siteName",
        // eslint-disable-next-line
        cell: ({ getValue, row }) => {
          const value = getValue();
          const isEnabled = row.original.collector.enabled;

          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }
          return (
            <Box sx={{ display: "flex" }}>
              <Tooltip title={value as string}>
                <Box
                  sx={{
                    color: !isEnabled ? "#dddddd" : "",
                    mt: "3px",
                    width: '150px'
                  }}
                >
                  {value as string}
                </Box>
              </Tooltip>
            </Box>
          );
        },
        enableSorting: false,
        size: 180,
      },
      {
        header: "Source",
        accessorKey: "collector.providerName",
        // eslint-disable-next-line
        cell: ({ getValue, row }) => {
          const value = getValue();
          const isEnabled = row.original.collector.enabled;

          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }
          return (
            <Box sx={{ display: "flex" }}>
              <Tooltip title={value as string}>
                <Box
                  sx={{
                    color: !isEnabled ? "#dddddd" : "",
                    mt: "3px",
                    width: '130px'
                  }}
                >
                  {value as string}
                </Box>
              </Tooltip>
            </Box>
          );
        },
        enableSorting: false,
        minSize: 150,
      },
      {
        header: TABLE_HEADERS.accountNumber,
        accessorKey: "collector.accountNumber",
        // eslint-disable-next-line
        cell: ({ getValue, row }) => {
          const value = getValue();
          const isEnabled = row.original.collector.enabled;

          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }
          return (
            <Tooltip title={value as string}>
              <Box sx={{ color: !isEnabled ? "#dddddd" : "", width: "70px" }}>
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        enableSorting: false,
        minSize: 175,
      },
      {
        header: TABLE_HEADERS.meterNumber,
        accessorKey: "collector.meterNumber",
        // eslint-disable-next-line
        cell: ({ getValue, row }) => {
          const value = getValue();
          const isEnabled = row.original.collector.enabled;

          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }
          return (
            <Tooltip title={value as string}>
              <Box sx={{ color: !isEnabled ? "#dddddd" : "", width: "70px" }}>
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        enableSorting: false,
        minSize: 155,
      },
      {
        header: "Type",
        accessorKey: "collector.utilityTypeName",
        // eslint-disable-next-line
        cell: ({ getValue, row }) => {
          const value = getValue() as UtilityBillTypeKey;
          const isEnabled = row.original.collector.enabled;

          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
              <Box sx={{ color: !isEnabled ? "#dddddd" : "", width: '70px' }}>
                <UtilityBillType type={value} />
              </Box>
          );
        },
        minSize: 65,
        enableSorting: false,
      },
      {
        header: "Unit",
        accessorKey: "unit",
        // eslint-disable-next-line
        cell: ({ getValue, row }) => {
          const value = getValue();
          const isEnabled = row.original.collector.enabled;

          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }
          return (
            <Tooltip title={value as string}>
              <Box sx={{ color: !isEnabled ? "#dddddd" : "" }}>
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        enableSorting: false,
        minSize: 90,
      },
      ...monthColumns,
    ];

    const currentTableData = isActivated ? activeCollectors : isActivatedMissing ? missingCollectors : tableRows;

  const table = useReactTable({
    data: currentTableData,
    columns: MISSING_DOCUMENTS_COLUMNS,
    enableSortingRemoval: false,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
  });

  const onResetAll = () => {
    setUtilityTypeId([])
    setSelectedSites([]);
    setSearchParams({});
    setFiscalYear("");
    setSelectedCountry([]);
    setIsActivated(false);
  };
  useEffect(() => {
    onResetAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitchChange = () => {
    setDataLoading(true);
    setIsActivated(!isActivated)
    setTimeout(() => {
      setDataLoading(false);
    }, 1000)
  };

  const handleSwitchChangeOnlyMissing = () => {
    setDataLoading(true);
    setIsActivatedMissing(!isActivatedMissing)
    setTimeout(() => {
      setDataLoading(false);
    }, 1000)
  };

  const debouncedSwitchChange = debounce(handleSwitchChange, 300);
  const debouncedSwitchChangeOnlyMissing = debounce(handleSwitchChangeOnlyMissing, 300);

  const utilityTypes = (allowedValues as any)?.utilityTypes?.map((t: any) => ({
    label: t.name,
    value: t.id
  }));

  return (
    <MarginWrapper>
      <Box sx={FILTERS_AND_ACTIONS_WRAPPER}>
        <Box sx={FLEX_ALIGN_CENTER_GAP_2}>
          <Box >
            <InputLabel
              sx={{
                fontSize: "12px",
                color: "black",
                fontWeight: 600,
                marginBottom: "4px",
              }}
              id="fiscal-year-label"
            >
              Fiscal Year
            </InputLabel>
            <FormControl sx={{ minWidth: 180 }}>
              <Select
                sx={{ height: "40px" }}
                labelId="fiscal-year-label"
                id="fiscal-year-select"
                value={fiscalYear}
                onChange={(event) =>
                  setFiscalYear(event.target.value as string)
                }
                placeholder="Fiscal Year"
                label="Fiscal Year"
              >
                {fiscalYears &&
                  fiscalYears.map((year: any) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{ minWidth: "200px", width: "fit-content" }}
          >
            <MultiselectControlledField
              disabled={isCountriesLoading}
              label="Country"
              options={countriesList}
              selectedValues={selectedCountry}
              setSelectedValues={setSelectedCountry}
              isAllSelectedOnEmptySelection
            />
          </Box>
          {(allowedValues as any)?.utilityTypes && <Box
            sx={{ minWidth: "200px", width: "fit-content" }}
          >
            <MultiselectControlledField
              label="Utility Type"
              options={utilityTypes}
              selectedValues={utilityTypeId}
              setSelectedValues={setUtilityTypeId}
              isAllSelectedOnEmptySelection
            />
          </Box>}
          <Box
            sx={{ minWidth: "200px", width: "fit-content" }}
          >
            <MultiselectControlledField
              label="Sites"
              disabled={isSitesLoading}
              selectedValues={selectedSites}
              setSelectedValues={setSelectedSites}
              options={sitesOptions}
              isAllSelectedOnEmptySelection
            />
          </Box>
          <Box
            sx={{ minWidth: "75px", width: "fit-content", marginTop: "20px" }}
          >
            <Button variant="outlined" onClick={fetchData}>
              Search
            </Button>
          </Box>
          <Box
            sx={{ minWidth: "135px", width: "fit-content", marginTop: "20px" }}
          >
            <Button variant="outlined" onClick={onResetAll}>
              Reset all filters
            </Button>
          </Box>
          <Box
            sx={{ minWidth: "85px", width: "fit-content", marginTop: "20px" }}
          >
            <FormControlLabel
              value="end"
              control={
                <Switch
                  color="primary"
                  value={isActivated}
                  onChange={debouncedSwitchChange}
                />}
              label="Show only Active"
              labelPlacement="end"
            />
            <FormControlLabel
              value="end"
              control={
                <Switch
                  color="primary"
                  value={isActivated}
                  onChange={debouncedSwitchChangeOnlyMissing}
                />}
              label="Show only missing"
              labelPlacement="end"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 12,
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              marginTop: "20px",
              marginLeft: "-145px",
              marginRight: "62px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  marginLeft: "150px",
                  width: "22px",
                  height: "18px",
                  backgroundColor: "#BFDBFE",
                  border: "solid 4px #BFDBFE",
                }}
              />
              <div
                style={{
                  fontSize: "10px",
                  color: "#000000",
                  maxWidth: "120px",
                  paddingLeft: "6px",

                  fontWeight: 600,
                }}
              >
                Data has been estimated using interpolation/extrapolation
              </div>
            </div>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              marginLeft: dataLoading ? "-150px" : "-140px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  width: "22px",
                  height: "18px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                    backgroundColor: "#BFDBFE",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    clipPath: "polygon(0 0, 100% 0, 0 100%)",
                    backgroundColor: "#FFFFFF",
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: "10px",
                  color: "#000000",
                  maxWidth: "110px",
                  paddingLeft: "4px",
                  fontWeight: 600,
                }}
              >
                Data has been partially estimated using
                interpolation/extrapolation
              </div>
            </div>
          </Box>
          <Box sx={{ marginTop: "20px", marginLeft: "-70px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  width: "22px",
                  height: "18px",
                  backgroundColor: "rgba(231, 2, 2, 0.1)",
                  border: "solid 0.5px #E70202",
                }}
              />

              <div
                style={{
                  fontSize: "10px",
                  color: "black",
                  maxWidth: "100px",
                  paddingLeft: "4px",
                }}
              >
                No data has been uploaded for this month
              </div>
            </div>
          </Box>
          <Box sx={{ marginTop: "20px", marginLeft: "-70px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  width: "22px",
                  height: "18px",
                  backgroundColor: "rgba(232, 125, 0, 0.1)",
                  border: "solid 0.5px #BA7B02",
                }}
              />

              <div
                style={{
                  fontSize: "10px",
                  color: "black",
                  maxWidth: "130px",
                  paddingLeft: "4px",
                }}
              >
                Data has been uploaded, but only for only some days this month
              </div>
            </div>
          </Box>
          <Box sx={{ marginTop: "20px", marginLeft: "-70px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  width: "22px",
                  height: "18px",
                  backgroundColor: "rgb(255, 255, 255)",
                  border: "solid 0.5px #000302",
                }}
              />

              <div
                style={{
                  fontSize: "10px",
                  color: "black",
                  maxWidth: "100px",
                  paddingLeft: "4px",
                }}
              >
                Data has been uploaded & is complete
              </div>
            </div>
          </Box>
          <Box sx={{ marginTop: "20px", marginLeft: "-100px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  width: "22px",
                  height: "18px",
                  backgroundColor: "#dddddd",
                  border: "solid 4px #dddddd",
                }}
              />

              <div
                style={{
                  fontSize: "10px",
                  color: "black",
                  maxWidth: "100px",
                  paddingLeft: "4px",
                }}
              >
                Collector Deactivated from previous month
              </div>
            </div>
          </Box>
        </Box>
      </Box>
      <Box>
        {selectedSites?.length === 0 && fiscalYear === "" && (
          <AppBar
            position="static"
            sx={{
              backgroundColor: "#243F36",
              height: "23px",
              top: "auto",
              bottom: 0,
              textAlign: "center",
              my: "1rem",
              width: "100%",
            }}
          >
            <Toolbar
              // variant="dense"
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ mb: 5 }} align="center">
                Please pick a year and site. If you opt to leave the site blank
                to see all sites, please note it might take a bit longer to
                process.
              </Typography>
            </Toolbar>
          </AppBar>
        )}
      </Box>
      {dataLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            table={table}
            nonePaddingColumnIds={NONE_PADDING_COLUMNS_IDS}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              page={pageNumber + 1}
              rowsCount={currentTableData.length || 0}
              rowsPerPage={pageSize}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onPageChange={onPageChange}
              onRowsPerPageChange={onPageSizeChange}
            />
          </Box>
        </>
      )}
      {!Number.isNaN(yearValue) && collectorId && month && (
        <div>
          <Modal open>
            <Box sx={style}>
              <CollectorMonthView
                year={yearValue}
                month={month}
                collectorMissingDocuments={collectorMissingDocuments}
                onClose={onCollectorMonthViewClose}
              // monthData={monthData}
              />
            </Box>
          </Modal>
        </div>
      )}
    </MarginWrapper>
  );
};
